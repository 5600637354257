@mixin flexMixin($direction, $jContent, $aitems) {
  display: flex;
  flex-direction: $direction;
  justify-content: $jContent;
  align-items: $aitems;
}
@mixin styler($color, $bgColor, $fontSize, $borderRadius) {
  color: $color;
  background-color: $bgColor;
  font-size: $fontSize;
  border-radius: $borderRadius;
}
@mixin containerMaker {
  margin: 0 auto;
  width: 100%;
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media (min-width: 992px) and (max-width: 1200px) {
    max-width: 960px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    max-width: 720px;
  }
  @media (max-width: 576px) and (max-width: 768px) {
    max-width: 540px;
  }
  @media (max-width: 576px) {
    max-width: 100%;
  }
}
@mixin imageFit {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
  height: auto;
}
@mixin media($size) {
  @if ($size == L) {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if ($size == T) {
    @media (max-width: 991px) {
      @content;
    }
  } @else if ($size == P) {
    @media (max-width: 768px) {
      @content;
    }
  }
}
