@import "variables";
@import "mixins";
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');
html {
    scroll-behavior: smooth;
}
html {
    width: 100% !important;
    overflow-x: hidden;
}
body {
    padding: 0;
    margin: 0;
    direction: ltr;
    overflow-x: hidden !important;
    font-display: swap;
    line-height: unset !important;
    width: 100% !important;
    min-width: 300px;
    font-size: 14px;
    // background-color: $bgc-color;
    background-color: #0e0e0e;
    font-family: "Figtree", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    color: #F1F3F6;
    * {
        box-sizing: border-box !important;
        margin: 0;
        padding: 0;
    }
    a {
        text-decoration: none !important;
    }
    ul {
        padding: 0;
        list-style-type: none;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}
#root {
    @include flexMixin(column, flex-start, center);
}
button {
    border: 0px solid transparent;
    outline: 0px solid transparent;
    cursor: pointer;
    transition: $transition;
}
.mm-width {
    max-width: 936px;
}
.main-header {
    position: absolute;
    width: 100%;
    height: 100px;
    @include flexMixin(row, flex-start, center);
    gap: 140px;
    .main-navigation-wrapper {
        width: 100%;
        @include flexMixin(row, flex-start, center);
        font-size: 16px;
    }
    .main-menu-ul {
        width: 100%;
        @include flexMixin(row, flex-start, center);
        gap: 80px;
        .menu-item {
            position: relative;
            &::after {
                height: 2px;
                width: 0px;
                background-color: $btn-color;
                position: absolute;
                left: 0;
                top: calc(100% + 4px);
                content: " ";
                transition: $transition;
            }
            &:hover {
                &:after {
                    width: 51px;
                }
            }
        }
        .menu-item.active {
            &:after {
                width: 51px;
            }
        }
        a {
            color: #F1F3F6;
            font-weight: 400;
        }
    }
    .burger-menu-btn {
        background-color: transparent;
        display: none;
    }
}

.hero-part-section {
    background-color: #0e0e0e;
    width: 100%;
    @include flexMixin(column, flex-start, center);
    padding: 180px 0 80px;
    .you-tube-h1-box{
        max-width: 936px;
        width: 100%;
        @include flexMixin(row,space-between,flex-start);
    }
    .you-tube-vide-iframe{
        border-radius: 8px;
        margin-right: 6px;
    }
    .content-box {
        max-width: 936px;
        @include flexMixin(column, flex-start, flex-start);
        width: 100%;
    }
    .hero-title {
        font-size: 72px;
        font-weight: 700px;
        line-height: 76.8px;
    }
    .hero-desc {
        font-size: 16px;
        line-height: 24px;
        margin: 16px 0 32px;
        font-weight: 400;
    }
    .hero-desc-mobile {
        display: none;
    }
    .video-place {
        width: 100%;
        @include flexMixin(row, center, center);
    }
    .mobile-version {
        display: none;
    }
}
.request-btn {
    width: 201px;
    height: 47px;
    font-size: 16px;
    @include flexMixin(row, center, center);
    background-color: $btn-color;
    border-radius: 8px;
    color: #F1F3F6;
    font-weight: 600;
    margin: 0 0 32px;
    &:hover {
        background-color: #0150b0;
    }
}
.simplcity-value-section {
    padding: 44px 0;
    @include flexMixin(column, flex-start, center);
    width: 100%;
    .section-description {
        margin: 0 0 32px;
    }
    .options-wrapper-part {
        width: 100%;
        @include flexMixin(column, flex-start, center);
        gap: 42px;
    }
    .request-btn {
        margin-bottom: 70px;
    }
    .valuedoptions-wrapper {
        width: 100%;
        height: 112px;
        @include flexMixin(row, center, center);
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;

    }
    .value-option-wrapper {
        transition: $transition;
        cursor: pointer;
        width: 100%;
        gap: 40px;
        @include flexMixin(column, flex-start, center);
        img {
            height: 60px;
            width: auto;
        }
        h3 {
            opacity: 0;
        }
    }
    .value-option-wrapper.active {
        .option-texts {
            width: 100%;
            @include flexMixin(column, flex-start, center);
            h3 {
                opacity: 1;
            }
            p {
                display: none;
            }
            margin: 0;
        }
    }
    .semi-active.value-option-wrapper {
        img {
            transition: $transition;
        }

        .option-texts {
            margin: 0;
        }
    }
    .valued-content-wrapper {
        width: 100%;
        position: relative;

        &::before {
            width: 4px;
            height: 576px;
            content: "";
            display: block;
            background-color: #0e0e0e;
            border-right: none;
            position: absolute;
            top: 0;
            left: 0px;
            z-index: 4
        }
    }
    .option-texts {
        transition: $transition;
        gap: 10px;
    }
    .option-title {
        font-size: 16px;
        font-weight: 700;
        color: $btn-color;
    }
    .option-text {
        font-size: 14px;
        line-height: 17px;
    }
}
.section-title {
    font-size: 56px;
    line-height: 67.2px;
    font-weight: 700;
    min-height: 68px;
    margin: 0 0 10px;
}
.section-description {
    font-size: 16px;
    max-width: 713px;
    width: 100%;
    text-align: center;
    line-height: 24px;
    color: #CECECE;
}
.made-by-pap-section {
    width: 100%;
    @include flexMixin(column, flex-start, center);
    padding: 43px 0;
    .view-packages-link {
        background-color: $btn-color;
        color: #F1F3F6;
        width: 158px;
        height: 47px;
        @include flexMixin(row, center, center);
        border-radius: 8px;
        margin: 32px 0;
        font-size: 16px;
        font-weight: 600;
        transition: $transition;
        &:hover {
            background-color: #0150b0;
        }
    }
    .all-mades-wrapper {
        width: 100%;
        max-width: 936px;
        @include flexMixin(row, flex-start, center);
        gap: 10px;
        overflow-x: scroll;
        overflow-y: hidden;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
        img {
            position: relative;
            &::before {
                content: " ";
                display: block;
                width: 200%;
                height: 100%;
                border-radius: 40px;
                background-color: #0e0e0e;
                position: absolute;
                background-color: red;
                top: 0;
                right: 0;
            }
        }
    }
    .maden-col {
        @include flexMixin(column, flex-start, center);
        transition: all ease-in-out .7s;
        opacity: 0;
        height: 385px;
    }
    .m-l-l-b,
    .m-r-l-b {
        margin-top: 8px;
    }
    .m-l-r-t,
    .m-r-r-t {
        margin: -8px 0 8px;
    }
    .col-left-1,
    .col-right-1 {
        transform: translateY(-50px);
    }
    .col-left-2,
    .col-right-2 {
        transform: translateY(50px);
    }
    .col-middle {
        min-width: 282px;
        transform: translateY(-50px);
    }
    .maden-span {
        width: 150px;
        @include flexMixin(row, center, center);
        border: 0.25px solid #3a3a3a;
        height: 80px;
        border-radius: 20px;
        padding: 15px 0 15px 15px;
        gap: 7px;
    }
    .maden-texts {
        width: 100%;
        @include flexMixin(column, flex-start, flex-start);
        gap: 5px;
    }
    .maden-title {
        font-size: 10px;
        font-weight: 700;
    }
    .maden-text {
        font-size: 10px;
        font-weight: 400;
        line-height: 14.5px;
        color: #949494;
    }
}
.made-by-pap-section.animate-show-case {
    .maden-col {
        opacity: 1;
    }
    .col-left-1,
    .col-right-1 {
        transform: translateY(0);
    }
    .col-left-2,
    .col-right-2 {
        transform: translateY(0);
    }
    .col-middle {
        transform: translateY(0);
    }
}
.plan-create-section {
    width: 100%;
    @include flexMixin(column, flex-start, center);
    padding: 32px;
    .plans-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 262px);
        grid-template-rows: repeat(2, 262px);
        row-gap: 24px;
        column-gap: 30px;
        margin: 32px 0 0;
    }
    .plan-wrapper {
        width: 100%;
        height: 100%;
        @include flexMixin(row, center, center);
        border: 2px solid #3a3a3a;
        font-size: 16px;
        line-height: 24px;
        color: #3A3A3A;
        transition: $transition;
        cursor: pointer;

        &:hover {
            border-color: $btn-color;
            color: #F1F3F6;
        }
    }
    .circle-plan {
        border-radius: 50%;
        padding: 31px 27px 31px 28px;
    }
    .semi-circle-plan {
        border-radius: 70px 150px 150px 70px;
        padding: 31px;
    }
    .square-plan {
        border-radius: 70px;
        padding: 31px;
    }
    .semi-triangle-plan {
        border-radius: 70px 70px 280px 70px;
        padding: 31px;
    }
}

.final-cta-section {
    width: 936px;
    min-height: 80px;
    @include flexMixin(row, space-between, center);
    border: 1px solid #3a3a3a;
    border-radius: 22px;
    background-color: #1a1a1a;
    padding: 16px;
    .final-message-wrapper {
        @include flexMixin(row, flex-start, center);
        gap: 8px;

        img {
            max-width: 100%;
            height: auto;
        }
    }
    .final-texts-wrapper {
        @include flexMixin(column, flex-start, flex-start);
        gap: 5px;
    }
    .dont-miss-title {
        font-size: 16px;
        font-weight: 700;
    }
    .dont-miss-text {
        font-size: 14px;
    }
    .request-btn {
        margin: 0;
    }
}
.main-footer {
    width: 100%;
    max-width: 937px;
    margin: 100px;
    padding: 50px 63px;
    @include flexMixin(row, space-between, flex-start);
    background-color: #1a1a1a;
    border: 1px solid #3a3a3a;
    border-radius: 22px;
    font-size: 16px;
    height: 304px;
    .footer-col {
        @include flexMixin(column, space-between, flex-start);
        height: 100%;
    }
    .footer-navigation {
        @include flexMixin(column, flex-start, flex-start);
    }
    .footer-li {
        @include flexMixin(row, flex-start, flex-start);
        height: 51px;
    }
    .footer-link {
        color: #CECECE;
        font-weight: 400;
    }
    .footer-col-3 {
        flex: 0 0 220px;
    }
    .social-links-wrapper {
        width: 100%;
        @include flexMixin(row, flex-start, center);
        gap: 24px;
    }
    .footer-logo {
        width: 100%;
        @include flexMixin(row, flex-end, center);
    }
}
.price-section {
    width: 100%;
    @include flexMixin(column, flex-start, center);
    margin: 170px 0 0;
    .price-table-wrapper {
        width: 100%;
        max-width: 600px;
        @include flexMixin(row, center, flex-start);
        height: auto;
        border: 1px solid #3a3a3a;
        margin: 32px 0 64px;
        border-radius: 22px;
    }
    .price-col {
        width: 100%;
        @include flexMixin(column, flex-start, center);
        height: 100%;
    }
    .price-col-2 {
        border-left: 1px solid #3a3a3a;
    }
    .price-label {
        flex: 0 0 93px;
        @include flexMixin(row, center, center);
        width: 100%;
        border-bottom: 1px solid #3a3a3a;
    }
    .button-wrapper {
        flex: 0 0 115px;
        @include flexMixin(row, center, center);
        width: 100%;
        border-top: 1px solid #3a3a3a;
    }
    .request-btn {
        margin: 0;
    }
    .price-items {
        height: 100%;
        @include flexMixin(column, center, center);
    }
    .price-item {
        font-size: 16px;
        font-weight: 400;
        height: 100px;
        @include flexMixin(row, center, center);
        width: 100%;
        text-align: center;
    }
    .need-bold {
        font-weight: 700;
    }
    .coming-soon-btn {
        @include flexMixin(row, center, center);
        width: 147px;
        height: 47px;
        font-size: 16px;
        font-weight: 600;
        line-height: 19.2px;
        border-radius: 8px;
        background-color: #3A3A3A;
        color: #F1F3F6;
        transition: $transition;
        &:hover {
            background-color: #292929;
        }
    }
    .plan-before {
        @include flexMixin(row, center, center);
        gap: 4px;
        &::before {
            width: 17px;
            height: 17px;
            display: block;
            background-color: $btn-color;
            border-radius: 50%;
            content: " ";
            position: relative;
            top: -1px;
        }
    }
    .publish-before {
        @include flexMixin(row, center, center);
        gap: 4px;
        &::before {
            width: 17px;
            height: 17px;
            display: block;
            background-color: $btn-color;
            border-radius: 5px;
            content: " ";
            position: relative;
            top: -1px;
        }
    }
}
.pap-in-a-nut-shell-section {
    @include flexMixin(column, flex-start, flex-start);
    gap: 16px;
    max-width: 936px;
    padding: 20px;
    .section-new-title {
        font-size: 32px;
        font-weight: 700px;
        line-height: 39px;
    }
    .nutshell-descrition {
        color: #CECECE;
        font-size: 16px;
        line-height: 24px;
    }
}
.burger-menu {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #141414;
    height: 0;
    transition: all ease-in-out .5s;
    overflow-y: hidden;
    z-index: 999999;
    button {
        background-color: transparent;
        position: relative;
        top: 18px;
    }
    .burger-ul {
        width: 100%;
        @include flexMixin(column, flex-start, flex-start);
        gap: 32px;
    }
    .burger-item {
        height: 50px;
        width: 100%;
        @include flexMixin(row, flex-start, center);
        padding: 32px 44px;
        a {
            font-size: 24px;
            color: #F1F3F6;
            font-weight: 400;
        }
    }
    .cross-holder {
        justify-content: flex-end;
    }
}
.animate-menu.burger-menu {
    height: 706px;
}
.value-video {
    max-width: unset;
}
// responsive 
@media screen and (max-width : 1024px) {
    .value-video {
        max-width: 100%;
        height: auto;
    }
    .main-header {
        padding: 0 44px;
        .main-navigation-wrapper {
            display: none;
        }
        justify-content: space-between;
        .burger-menu-btn {
            display: block;
        }
    }
    .hero-part-section {
        padding: 180px 30px 80px;
        .hero-title{
            font-size: 64px;
        }
        .you-tube-vide-iframe{
            max-width: 352px;
            max-height: 197px;
        }
    }
    .simplcity-value-section {
        .options-wrapper-part {
            @include flexMixin(column, flex-start, center);
        }
        .valuedoptions-wrapper {
            width: 100%;
        }
        .value-option-wrapper {
            @include flexMixin(column, flex-start, center);
            background-color: unset;
            border: none;
            width: unset;
            flex: 0 0 120px;
            padding: 0;
            position: relative;
            height: 100%;
            img {
                max-width: unset;
                height: 50px;
            }
            &:hover {
                border: none;
                background-color: unset;
            }
            h3 {
                opacity: 0;
                color: $btn-color;
                text-align: center;
                font-size: 16px;
                transition: $transition;
            }
        }
        .option-texts {
            display: none;
        }
        .valued-content-wrapper {
            width: 100%;
            padding: 0 30px;
            @include flexMixin(row, flex-end, center);
        }
    }
    .made-by-pap-section {
        position: relative;
        padding: 43px 20px;
        .all-mades-wrapper {
            justify-content: flex-start;
            overflow-x: scroll;
            padding: 0 20px 0px 20px;
            flex-wrap: nowrap;

        }
    }
    .final-cta-section {
        max-width: 776px;
    }
    .main-footer {
        max-width: 776px;
        padding: 50px 44px;
    }
}
@media screen and (max-width : 891px){
    .hero-part-section{
        .hero-title{
            font-size: 57px;
        }
        .you-tube-vide-iframe{
            width: 332px;
            height: 184px;
        }
    }
}
@media screen and (max-width : 768px) {
    .price-section {
        margin: 121px 0 0;
        .section-title {
            font-size: 32px;
            min-height: 48px;
            margin: 0;
        }
        .section-description {
            font-size: 15px;
        }
        .price-table-wrapper {
            @include flexMixin(column, flex-start, center);
            gap: 24px;
            border: none;
            padding: 0 24px;
            height: auto;
        }
        .price-col {
            width: 100%;
            max-width: 272px;
            height: 464px;
            border: 1px solid #3A3A3A;
            border-radius: 22px;
        }
        .price-label {
            font-size: 24px;
            flex: 0 0 64px;
        }
        .button-wrapper {
            flex: 0 0 61px;
        }
        .price-item {
            font-size: 14px;
            text-align: center;
            height: 55px;
        }
        .coming-soon-btn {
            width: 139px;
            height: 30px;
            font-size: 12px;
        }
    }
    .request-btn {
        width: 139px;
        height: 30px;
        font-size: 12px;
    }
    .pap-in-a-nut-shell-section {
        padding: 0 24px;
        max-width: 300px;
        .section-new-title {
            font-size: 22px;
        }
        .nutshell-descrition {
            font-size: 14px;
        }
    }
    .main-footer {
        margin: 56px 0 20px;
        max-width: 273px;
        @include flexMixin(row, flex-start, flex-start);
        height: auto;
        position: relative;
        padding: 15px 12px 80px;
        flex-wrap: wrap;
        gap: 37px;
        .social-links-wrapper {
            margin-bottom: -20px;
            gap: 12px;

            img {
                width: 16px;
            }
        }
        .footer-col-3 {
            flex: 1 0 100%;
            order: -1;
            @include flexMixin(row, flex-start, flex-start);
        }
        .footer-logo {
            position: absolute;
            bottom: 14px;
            left: 12px;
            justify-content: flex-start;
        }
    }
    .hero-part-section {
        padding: 100px 25px 10px;
        .you-tube-h1-box{
            @include flexMixin(column-reverse,flex-start,center);
            gap: 16px;
        }
        .you-tube-embed{
            width: 100%;
            @include flexMixin(row,center,center);
        }
        .you-tube-vide-iframe{
            max-width: unset;
            max-height: unset;
            min-width: 272px;
            min-height: 152px;
            width: calc(100vw - 78px);
            height: calc(50vw - 26px);
        }
        .text-action-btn-box{
            @include flexMixin(column,flex-start,center);
        }
        .hero-title {
            font-size: 48px;
            line-height: 50px;
            width: 100%;
            text-align: center;
        }
        .content-box {
            align-items: center;
        }
        .hero-desc {
            display: none;
        }
        .hero-desc-mobile {
            display: block;
            text-align: center;
            font-size: 14px;
            line-height: 18px;
            min-height: 54px;
            margin: 20px 0 12px;
        }
        .desktop-version {
            display: none;
        }
        .mobile-version {
            display: block;
        }
    }
    .simplcity-value-section {
        padding: 0 12px 44px;
        margin: 60px 0 77px;
        .options-wrapper-part {
            gap: 20px;
        }
        .valuedoptions-wrapper {
            width: 100%;
            @include flexMixin(row, space-between, center);
            padding: 10px 40px 10px 0px;
            gap: 10px;
            overflow: scroll;
            overflow-y: visible;
            height: 130px;
        }
        .value-option-wrapper {
            flex: 0 0 130px;
            position: relative;
            gap: 20px;
            height: 100%;

        }
        .valued-content-wrapper {
            padding: 0;

            &::before {
                display: none;
            }
        }
    }
    .section-title {
        font-size: 32px;
        line-height: 29px;
        height: auto;
        min-height: unset;
        width: 100%;
        text-align: center;
    }
    .section-description {
        font-size: 14px;
        line-height: 20px;
        margin: 0 0 16px;
    }
    .plan-create-section {
        .section-title {
            text-align: center;
            width: 90%;
            line-height: 40px;
        }
        .section-description {
            width: 235px;
        }
        .plans-wrapper {
            width: 100%;
            overflow-x: scroll;
            @include flexMixin(row, flex-start, center);
            flex-wrap: nowrap;
            padding: 0 20px 0;

            &::-webkit-scrollbar {
                display: none;
            }

            -ms-overflow-style: none;
            scrollbar-width: none;
        }
        .plan-wrapper {
            flex: 0 0 257px;
            height: 257px;
        }
        .circle-plan {
            padding-right: 22px;
        }
    }
    .final-cta-section {
        width: 273px;
        @include flexMixin(column, space-between, center);
        height: 120px;
        padding: 18px;
        .dont-miss-title {
            font-size: 12px;
            font-weight: 400;
        }
        .dont-miss-text {
            font-size: 12px;
            font-weight: 700;
        }
        .final-message-wrapper {
            gap: 12px;

            img {
                width: 34px;
            }
        }
    }
    .made-by-pap-section {
        .view-packages-link {
            width: 107px;
            height: 30px;
            font-size: 12px;
        }
    }
}
.video-hero {
    width: 100%;
    max-width: 100%;
    height: auto !important;
    object-fit: contain !important;
}
.typeform-pop-up-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px;
    background-color: #121212b0;
    // background-color: red;ß
    @include flexMixin(row, center, center);
    z-index: 999999999;

    .cross-icon {
        background-color: transparent;
        @include flexMixin(row, center, center);
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }
}
.no-scroll {
    height: 100vh;
    overflow-y: hidden;
}
video {

    &:focus,
    &:focus-visible {
        border: 0px solid transparent !important;
        outline: 0px solid transparent !important;
    }
}
.desktop-version,
.value-video {
    opacity: 0.99;
}
.video-place {
    position: relative;
}
.mobile-frame {
    position: absolute;
    top: 0;
    width: 300px;
    z-index: 3;
    max-width: unset !important;
    min-width: 300px;
}
.behind-video {
    position: absolute;
    top: 1px;
    width: 290px;
    z-index: 1;
    max-width: unset !important;
    min-width: 290px;
    background-color: #444;
    height: 560px;
    border-radius: 40px;
}
.video-hero.mobile-version {
    min-width: 275px;
    width: 275px;
    position: relative;
    top: 8px;
    height: 552px !important;
    z-index: 2;
}